<template>
  <div v-frag>
    <hero-with-image :title="pageTitle" subtitle="repairForm"></hero-with-image>
    <v-container class="mt-6">
      <div v-html="$t('swPickupInfoText')"></div>
    </v-container>
    <szervizgar-futar-form :service-id="serviceId" :is-reused-form="isReusedForm"></szervizgar-futar-form>
  </div>
</template>

<script>
import HeroWithImage from 'src/components/titles/HeroWithImage';
import SzervizgarFutarForm from 'src/components/forms/SzervizgarFutarForm.vue';


export default {
  name: 'SzervizgarFutarPage',
  components: {
    HeroWithImage, SzervizgarFutarForm,
  },
  props: {
    pageTitle: {
      type: String,
      default: 'services.swPickup',
    },
    serviceId: {
      type: Number,
      default: 200,
    },
  },
  async beforeMount() {
    if (this.$route.params.identicalHash) {
      if (this.$route.params.identicalHash === 'uj-megrendeles') {
        this.isReusedForm = false;
      } else {
        this.isReusedForm = true;
      }
    } else {
      this.isReusedForm = false;
    }
  },
  mounted() {
    // this.contactForm.sourceSystem = this.$route.name.substring(14);
  },
  data() {
    return {
      isReusedForm: false,
    };
  },
  methods: {
    //
  },
  watch: {
    //
  },
};
</script>

<style scoped>

</style>
